var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "variable-pricing-form" },
    [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.active")))
            ]),
            _c("vs-switch", {
              staticClass: "mt-3",
              attrs: { type: "boolean" },
              model: {
                value: _vm.variablePricing.active,
                callback: function($$v) {
                  _vm.$set(_vm.variablePricing, "active", $$v)
                },
                expression: "variablePricing.active"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "vx-row mb-6" },
        _vm._l(_vm.languages(), function(language, index) {
          return _c(
            "div",
            { key: index, staticClass: "vx-col w-1/5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(_vm._s(_vm.$t("shops.name_" + language)))
              ]),
              _vm.langRequired(language)
                ? _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "w-full",
                    attrs: {
                      name: "name",
                      danger: _vm.errorName && _vm.errors.has("name"),
                      "danger-text": _vm.$t(
                        "shops.errors.variable_pricing_name_is_required"
                      )
                    },
                    model: {
                      value: _vm.variablePricing.name,
                      callback: function($$v) {
                        _vm.$set(_vm.variablePricing, "name", $$v)
                      },
                      expression: "variablePricing.name"
                    }
                  })
                : _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { name: "name" + language },
                    model: {
                      value: _vm.variablePricing.translations[language].name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.variablePricing.translations[language],
                          "name",
                          $$v
                        )
                      },
                      expression: "variablePricing.translations[language].name"
                    }
                  })
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "vx-row mb-6" },
        _vm._l(_vm.languages(), function(language, index) {
          return _c(
            "div",
            { key: index, staticClass: "vx-col w-1/3" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(_vm._s(_vm.$t("shops.description_" + language)))
              ]),
              _vm.langRequired(language)
                ? _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { name: "description" },
                    model: {
                      value: _vm.variablePricing.description,
                      callback: function($$v) {
                        _vm.$set(_vm.variablePricing, "description", $$v)
                      },
                      expression: "variablePricing.description"
                    }
                  })
                : _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { name: "description" + language },
                    model: {
                      value:
                        _vm.variablePricing.translations[language].description,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.variablePricing.translations[language],
                          "description",
                          $$v
                        )
                      },
                      expression:
                        "variablePricing.translations[language].description"
                    }
                  })
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.discount_type")))
            ]),
            _c("v-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full select-large",
              class: {
                "danger-border-select":
                  _vm.errorDiscountType && _vm.errors.has("discount_type")
              },
              attrs: {
                options: _vm.discount_type,
                value: _vm.variablePricing.discount_type.id,
                name: "discount_type",
                placeholder: _vm.$t("shops.select_discount_type"),
                "danger-text": _vm.$t("shops.errors.discount_type_is_required"),
                danger: _vm.errorDiscountType && _vm.errors.has("discount_type")
              },
              on: {
                input: function(discount) {
                  return _vm.selectDiscount(discount)
                }
              },
              model: {
                value: _vm.variablePricing.discount_type.text,
                callback: function($$v) {
                  _vm.$set(_vm.variablePricing.discount_type, "text", $$v)
                },
                expression: "variablePricing.discount_type.text"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "vx-col" }, [
          _c(
            "div",
            { staticClass: "w-1/1" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(_vm._s(_vm.$t("shops.discount_amount")))
              ]),
              _c("vs-input-number", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "w-full",
                attrs: {
                  type: "number",
                  name: "amount",
                  danger: _vm.errorAmount && _vm.errors.has("amount"),
                  "danger-text": _vm.$t(
                    "shops.errors.discount_amount_is_required"
                  ),
                  min: "0"
                },
                model: {
                  value: _vm.variablePricing.amount,
                  callback: function($$v) {
                    _vm.$set(_vm.variablePricing, "amount", $$v)
                  },
                  expression: "variablePricing.amount"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.product")))
            ]),
            _c("v-select", {
              staticClass: "w-full select-large",
              attrs: {
                options: _vm.products.map(function(p) {
                  return { label: p.name, value: p.id }
                }),
                "v-model":
                  _vm.variablePricing.product === null
                    ? ""
                    : _vm.variablePricing.product,
                value:
                  _vm.variablePricing.product === null
                    ? ""
                    : _vm.variablePricing.product.name,
                name: "product",
                placeholder: _vm.$t("shops.all_products")
              },
              on: {
                input: function(product) {
                  return _vm.selectProduct(product)
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.participant")))
            ]),
            _c("v-select", {
              staticClass: "w-full select-large",
              attrs: {
                options: _vm.participants.map(function(p) {
                  return { label: p.name, value: p.id }
                }),
                "v-model":
                  _vm.variablePricing.participant === null
                    ? ""
                    : _vm.variablePricing.participant,
                value:
                  _vm.variablePricing.participant === null
                    ? ""
                    : _vm.variablePricing.participant.name,
                name: "participant",
                placeholder: _vm.$t("shops.all_participants")
              },
              on: {
                input: function(participant) {
                  return _vm.selectParticipant(participant)
                }
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "vx-col" }, [
          _c(
            "div",
            { staticClass: "w-1/1" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(_vm._s(_vm.$t("shops.units")))
              ]),
              _vm.variablePricing.quantity === 0
                ? _c("vs-input-number", {
                    staticClass: "w-full",
                    attrs: { type: "number", name: "quantity", min: "0" },
                    model: {
                      value: _vm.variablePricing.quantity,
                      callback: function($$v) {
                        _vm.$set(_vm.variablePricing, "quantity", $$v)
                      },
                      expression: "variablePricing.quantity"
                    }
                  })
                : _c("vs-input-number", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "w-full",
                    attrs: {
                      type: "number",
                      name: "quantity",
                      danger: _vm.errorQuantity && _vm.errors.has("quantity"),
                      "danger-text": _vm.$t("shops.errors.unit_is_required"),
                      min: "0"
                    },
                    model: {
                      value: _vm.variablePricing.quantity,
                      callback: function($$v) {
                        _vm.$set(_vm.variablePricing, "quantity", $$v)
                      },
                      expression: "variablePricing.quantity"
                    }
                  })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/5 mb-6" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.variable_pricing_type")))
            ]),
            _c("v-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full select-large",
              class: {
                "danger-border-select": _vm.errorType && _vm.errors.has("type")
              },
              attrs: {
                options: _vm.variablePricingType,
                value: _vm.variablePricing.type.id,
                name: "type",
                placeholder: _vm.$t("shops.select_type"),
                "danger-text": _vm.$t("shops.errors.type_is_required"),
                danger: _vm.errorType && _vm.errors.has("type")
              },
              on: {
                input: function(dpt) {
                  return _vm.selectVariablePricingType(dpt)
                }
              },
              model: {
                value: _vm.variablePricing.type.text,
                callback: function($$v) {
                  _vm.$set(_vm.variablePricing.type, "text", $$v)
                },
                expression: "variablePricing.type.text"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "pl-4" }, [
          _vm.variablePricing.type.id === "period"
            ? _c(
                "div",
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(_vm._s(_vm.$t("shops.select_the_period_to_sell")))
                  ]),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "w-full select-large",
                    class: {
                      "danger-border-select":
                        _vm.errorPeriod && _vm.errors.has("period")
                    },
                    attrs: {
                      options: _vm.periods.map(function(period) {
                        return { label: period.name, value: period.id }
                      }),
                      value: _vm.variablePricing.period.id,
                      name: "period",
                      placeholder: _vm.$t("shops.select_period"),
                      "danger-text": _vm.$t(
                        "shops.errors.period_id_is_required"
                      ),
                      danger: _vm.errorPeriod && _vm.errors.has("period")
                    },
                    on: {
                      input: function(period) {
                        return _vm.selectPeriod(period)
                      }
                    },
                    model: {
                      value: _vm.variablePricing.period.name,
                      callback: function($$v) {
                        _vm.$set(_vm.variablePricing.period, "name", $$v)
                      },
                      expression: "variablePricing.period.name"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.variablePricing.type.id === "anticipation"
            ? _c("div", [
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/1" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("shops.set_the_amount_of_anticipated"))
                        )
                      ]),
                      _c("vs-input-number", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "w-1/5",
                        attrs: {
                          type: "number",
                          name: "anticipation",
                          danger:
                            _vm.errorAnticipation &&
                            _vm.errors.has("anticipation"),
                          "danger-text": _vm.$t(
                            "shops.errors.unit_is_required"
                          ),
                          min: "0"
                        },
                        model: {
                          value: _vm.variablePricing.anticipation,
                          callback: function($$v) {
                            _vm.$set(_vm.variablePricing, "anticipation", $$v)
                          },
                          expression: "variablePricing.anticipation"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ])
      ]),
      _c("vs-divider"),
      _c(
        "vs-button",
        {
          staticClass: "mt-base",
          attrs: { type: "filled" },
          on: {
            click: function($event) {
              return _vm.validateAndSubmit()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("shops.save")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }