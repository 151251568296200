const shopLanguages = {
    methods: {
        languages() {
            return process.env.VUE_APP_SHOPS_LANGUAGES.split(",").filter(lang => lang)
        },
        langRequired(language) {
            return language === 'en'
        },
        translationsDefault(fields) {
            return this.languages().reduce((carry, lang) => {
                carry[lang] = {}
                fields.forEach(field => {
                    carry[lang][field] = ""
                });
                return carry
            }, {})
        }
    }
}
  
export default shopLanguages