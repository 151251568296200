<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <vs-button v-if="showForm" @click="onCreate()">{{ $t("shops.back") }}</vs-button>

      <transition name="fade" mode="out-in">
        <div class="mb-base" v-if="!showForm">
          <data-table
            class="datatableVariablePricing"
            :data="tableData"
            :options="tableOptions"
            @create="onCreate"
            @remove="onRemove($event, 'shops.promotion')"
            @update="onUpdate"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
          />
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="mb-base mt-10" v-if="showForm">
          <variable-pricing-form v-model="formData" @submit="handleVariablePricingFormSubmit" />
        </div>
      </transition>
    </vx-card>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters, mapActions } from "vuex";
import DataTable from "@/modules/Shared/Components/table/DataTable";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";
import VariablePricingForm from "@/modules/Shops/Pages/forms/VariablePricingForm.vue";

export default {
  name: "VariablePricing",

  mixins: [ notifications, table_functions, shopLanguages ],

  components: {
    DataTable,
    VariablePricingForm,
  },

  data() {
    return {
      showForm: false,
      formData: {},
      defaultSort: "-id",
      exportPayload: {}
    };
  },

  created() {
    this.$store.commit("shops/dynamic_pricings/RESET_RESOURCE");
    this.loadFormData();
  },

  computed: {
    ...mapGetters("shops/dynamic_pricings", {
      variablePricingList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      variablePricingById: "getById",
    }),
    ...mapGetters("shops/products", {
      products: "getAll"
    }),
    ...mapGetters("shops/participants", {
      participants: "getAll"
    }),
    ...mapGetters("shops/periods", {
      periods: "getAll"
    }),

    tableData() {
      return this.variablePricingList.map(this.variablePricingToTableData);
    },

    tableOptions() {
      return {
        actions: {
          create: true,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
      }
    }
  },

  methods: {
    ...mapActions("shops/dynamic_pricings", {
      loadData: "load",
      removeData: "remove",
      updateVariablePricing: "update",
      createVariablePricing: "create"
    }),
    ...mapActions("shops/products", { loadProducts: "load" }),
    ...mapActions("shops/participants", { loadParticipants: "load" }),
    ...mapActions("shops/periods", { loadPeriods: "load" }),

    variablePricingToTableData(variablePricing) {
      return {
        id: variablePricing.id,
        name: variablePricing.name,
        description: variablePricing.description,
        type: variablePricing.type === 'period' ? 'Discounted Period' : 'Anticipated Sales',
        discount_type: variablePricing.discount_type === 'percent' ? '%' : 'Amount',
        amount: variablePricing.amount,
        period: variablePricing.period !== null ? variablePricing.period.active : false
      }
    },


    async loadFormData() {
      this.$vs.loading();
      await this.loadProducts({ page_size: 1000 });
      await this.loadParticipants({ page_size: 1000 });
      await this.loadPeriods({ page_size: 1000, marketing: true });
      this.$vs.loading.close();
    },

    async handleVariablePricingFormSubmit(variablePricing) {
      this.$vs.loading();
      try {
        if (variablePricing.id === 0) {
          await this.createVariablePricing(variablePricing);
          this.notifySuccess(
            i18n.t("shops.save"),
            `${i18n.t('shops.variable-pricing.title')} ${this.formData.name} ${i18n.t('shops.has_been_created_successfully')}`
          );
        } else {
          await this.updateVariablePricing(variablePricing);
          this.notifySuccess(
            i18n.t("shops.updated"),
            `${i18n.t('shops.variable-pricing.title')} ${variablePricing.name} ${i18n.t('shops.has_been_updated_successfully')}`
          );
        }
        this.formData = this.default();
        this.showForm = false;
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_save_variable_pricing"), error);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async onUpdate(variablePricing) {
      const translationsDefault = this.translationsDefault(['name', 'description'])

      this.formData = Object.assign({}, this.formData, {
        ...this.variablePricingById(variablePricing.id),
        translations: { ...translationsDefault, ...this.variablePricingById(variablePricing.id).translations }
      });

      await this.$store.dispatch("shops/dynamic_pricings/assignTranslations",this.formData);

      this.showForm = true;
    },

    default() {
      return {
        id: 0,
        active: false,
        translations: this.translationsDefault(['name', 'description']),
        name: "",
        description: "",
        max_qty_user: 0,
        amount: 0,
        quantity: 999999,
        anticipation: 0,
        type: {},
        discount_type: {},
        product: {},
        participant: {},
        period: {},
        product_id: 0,
        participant_id: 0,
        period_id: 0
      }
    }
  }
};
</script>

<style>
.danger-border div,
.danger-border-select div input {
  border-color: red !important;
}

.datatableVariablePricing .vs-table--header {
  @apply flex;
  @apply flex-wrap;
}

.datatableVariablePricing .vs-button {
  @apply order-3;
}

.datatableVariablePricing .con-input-search {
  @apply order-2;
  @apply mr-auto;
  @apply ml-4;
}
</style>
