<template>
  <div class="variable-pricing-form">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t("shops.active") }}</label>
        <vs-switch class="mt-3" v-model="variablePricing.active" type="boolean" />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t(`shops.name_${language}`) }}</label>
        <vs-input v-if="langRequired(language)"
          v-model="variablePricing.name"
          v-validate="'required'"
          class="w-full"
          name="name"
          :danger="errorName && errors.has('name')"
          :danger-text="$t('shops.errors.variable_pricing_name_is_required')" />
        <vs-input v-else v-model="variablePricing.translations[language].name" class="w-full" :name="`name${language}`" />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t(`shops.description_${language}`) }}</label>
        <vs-input v-if="langRequired(language)" v-model="variablePricing.description" class="w-full" name="description" />
        <vs-input v-else v-model="variablePricing.translations[language].description" class="w-full" :name="`description${language}`" />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.discount_type") }}</label>
        <v-select
          :options="discount_type"
          @input="(discount) => selectDiscount(discount)"
          v-model="variablePricing.discount_type.text"
          :value="variablePricing.discount_type.id"
          class="w-full select-large"
          :class="{'danger-border-select': errorDiscountType && errors.has('discount_type')}"
          name="discount_type"
          :placeholder="$t('shops.select_discount_type')"
          v-validate="'required'"
          :danger-text="$t('shops.errors.discount_type_is_required')"
          :danger="errorDiscountType && errors.has('discount_type')" />
      </div>

      <div class="vx-col">
        <div class="w-1/1">
          <label class="vs-input--label">{{ $t("shops.discount_amount") }}</label>
          <vs-input-number
            v-model="variablePricing.amount"
            type="number"
            v-validate="'required'"
            class="w-full"
            name="amount"
            :danger="errorAmount && errors.has('amount')"
            :danger-text="$t('shops.errors.discount_amount_is_required')"
            min="0" />
        </div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.product") }}</label>
        <v-select
          :options="products.map((p) => ({ label: p.name, value: p.id }))"
          @input="(product) => selectProduct(product)"
          :v-model="variablePricing.product === null ? '' : variablePricing.product"
          :value="variablePricing.product === null ? '' : variablePricing.product.name"
          class="w-full select-large"
          name="product"
          :placeholder="$t('shops.all_products')" />
      </div>
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.participant") }}</label>
        <v-select
          :options="participants.map((p) => ({ label: p.name, value: p.id }))"
          @input="(participant) => selectParticipant(participant)"
          :v-model="variablePricing.participant === null ? '' : variablePricing.participant"
          :value="variablePricing.participant === null ? '' : variablePricing.participant.name"
          class="w-full select-large"
          name="participant"
          :placeholder="$t('shops.all_participants')" />
      </div>
      <div class="vx-col">
        <div class="w-1/1">
          <label class="vs-input--label">{{ $t("shops.units") }}</label>
          <vs-input-number
            v-if="variablePricing.quantity === 0"
            v-model="variablePricing.quantity"
            type="number"
            class="w-full"
            name="quantity"
            min="0" />
          <vs-input-number
            v-else
            v-model="variablePricing.quantity"
            type="number"
            v-validate="'required'"
            class="w-full"
            name="quantity"
            :danger="errorQuantity && errors.has('quantity')"
            :danger-text="$t('shops.errors.unit_is_required')"
            min="0" />
        </div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/5 mb-6">
        <label class="vs-input--label">{{ $t("shops.variable_pricing_type") }}</label>
        <v-select
          :options="variablePricingType"
          @input="(dpt) => selectVariablePricingType(dpt)"
          v-model="variablePricing.type.text"
          :value="variablePricing.type.id"
          class="w-full select-large"
          :class="{'danger-border-select': errorType && errors.has('type')}"
          name="type"
          :placeholder="$t('shops.select_type')"
          v-validate="'required'"
          :danger-text="$t('shops.errors.type_is_required')"
          :danger="errorType && errors.has('type')" />
      </div>

      <div class="pl-4">
        <div v-if="variablePricing.type.id === 'period'">
          <label class="vs-input--label">{{ $t("shops.select_the_period_to_sell") }}</label>
          <v-select
            :options="periods.map((period) => ({ label: period.name, value: period.id }))"
            v-model="variablePricing.period.name"
            :value="variablePricing.period.id"
            @input="(period) => selectPeriod(period)"
            class="w-full select-large"
            :class="{ 'danger-border-select': errorPeriod && errors.has('period') }"
            name="period"
            :placeholder="$t('shops.select_period')"
            v-validate="'required'"
            :danger-text="$t('shops.errors.period_id_is_required')"
            :danger="errorPeriod && errors.has('period')" />
        </div>

        <div v-if="variablePricing.type.id === 'anticipation'">
          <div class="vx-row mb-6">
            <div class="vx-col w-1/1">
              <label class="vs-input--label">{{$t("shops.set_the_amount_of_anticipated")}}</label>
              <vs-input-number
                v-model="variablePricing.anticipation"
                v-validate="'required'"
                type="number"
                name="anticipation"
                :danger="errorAnticipation && errors.has('anticipation')"
                :danger-text="$t('shops.errors.unit_is_required')"
                min="0"
                class="w-1/5"
              />
            </div>
          </div>
        </div>

      </div>
    </div>
    <vs-divider />
    <vs-button @click="validateAndSubmit()" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";

export default {
  name: "VariablePricingForm",

  mixins: [ notifications, shopLanguages ],

  components: {
    Datepicker,
  },
  props: {
    value: { type: Object, default: null },
  },
  data() {
    return {
      discount_type: [
        { label: "%", value: "percent" },
        { label: "Amount", value: "amount" },
      ],
      variablePricingType: [
        { label: "Discounted Period", value: "period" },
        { label: "Anticipated Sales", value: "anticipation" },
      ],
      errorCode: false,
      errorType: false,
      errorAmount: false,
      errorQuantity: false,
      errorProduct: false,
      errorParticipant: false,
      errorMaxUser: false,
      errorAnticipation: false,
      errorPromotionType: false,
      errorFromDate: false,
      errorToDate: false,
      errorDiscountType: false,
      errorPeriod: false,
      errorPeriodRange: false,
      errorName: false,
      variablePricing: {}
    };
  },

  computed: {
    ...mapGetters("shops/products", {
      products: "getAll",
    }),
    ...mapGetters("shops/participants", {
      participants: "getAll",
    }),
    ...mapGetters("shops/periods", {
      periods: "getAll",
    }),
    ...mapGetters("shops/period_ranges", {
      period_ranges: "getByPeriodId",
    }),
  },

  methods: {
    async validateAndSubmit() {
      const payload = { ...this.languages().reduce((carry, lang) => {
          carry[lang] = this.variablePricing.translations[lang]
          return carry
        }, {}), ...this.variablePricing }

      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.variablePricing.product_id === 0 && this.variablePricing.product) {
            payload.product_id = this.variablePricing.product.id;
          } else if (this.variablePricing.product_id > 0) {
            payload.product_id = this.variablePricing.product_id;
          } else {
            this.variablePricing.product_id = null;
          }

          if (this.variablePricing.participant_id === 0 && this.variablePricing.participant) {
            payload.participant_id = this.variablePricing.participant.id;
          } else if (this.variablePricing.participant_id > 0) {
            payload.participant_id = this.variablePricing.participant_id;
          } else {
            this.variablePricing.participant_id = null;
          }

          if (this.variablePricing.period_id === 0)
            payload.period_id = this.variablePricing.period.id;

          payload.discount_type = this.variablePricing.discount_type.id;
          payload.type = this.variablePricing.type.id;

          this.$emit("submit", payload);
        } else {
          this.notifyWarning(
            i18n.t("shops.errors.warning"),
            i18n.t("shops.errors.variable_pricing_validation_failed")
          );
          if (!this.variablePricing.name) this.errorName = true;
          if (!this.variablePricing.amount) this.errorAmount = true;
          if (!this.variablePricing.type.id) this.errorType = true;
          if (!this.variablePricing.period_id) this.errorPeriod = true;
          if (!this.variablePricing.discount_type.id) this.errorDiscountType = true;
        }
      });
    },

    selectDiscount(option) {
      this.variablePricing.discount_type.id = option === null ? option : option.value;
      this.variablePricing.discount_type.name = option === null ? option : option.label;
    },

    selectProduct(option) {
      this.variablePricing.product = {};
      if (option) {
        this.variablePricing.product.id = option.value;
        this.variablePricing.product.name = option.label;
      }
      this.variablePricing.product_id = option === null ? option : option.value;
    },

    selectParticipant(option) {
      this.variablePricing.participant = {};
      if (option) {
        this.variablePricing.participant.id = option.value;
        this.variablePricing.participant.name = option.label;
      }
      this.variablePricing.participant_id = option === null ? option : option.value;
    },

    selectVariablePricingType(option) {
      this.variablePricing.type.id = option === null ? option : option.value;
      this.variablePricing.type.name = option === null ? option : option.label;
    },

    selectPeriod(option) {
      this.variablePricing.period_id = option === null ? option : option.value;
    },
  },

  created() {
    this.variablePricing = JSON.parse(JSON.stringify(this.value))
    if (Object.keys(this.variablePricing.discount_type).length !== 0) {
      this.variablePricing.discount_type =
        this.variablePricing.discount_type === "percent"
          ? Object.assign({ id: "percent" }, { text: "%" })
          : Object.assign({ id: "amount" }, { text: "Amount" });
    }
    if (Object.keys(this.variablePricing.type).length !== 0)
      this.variablePricing.type =
        this.variablePricing.type === "anticipation"
          ? Object.assign({ id: "anticipation" }, { text: "Anticipated Sales" })
          : Object.assign({ id: "period" }, { text: "Discounted Period" });
    if (this.variablePricing.period === null) this.variablePricing.period = {};
  },
};
</script>

<style></style>
